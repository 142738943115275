import { Environment, IConfig } from './config.interfaces';

const prodConfig: IConfig = {
  onboardingFeeStartDate: new Date('2022-12-31T23:00:00Z'),
  environment: Environment.PRODUCTION,
  backendV2BaseUrl: 'https://backend-v2.idana.app',
  questionnairesV2BaseUrl: 'https://questionnaires-v2.idana.app/api/v2',
  stripeConfig: {
    publicKey: 'pk_live_ZhHWbmT9U4gUBG0Oxg0GCyt1',
  },
  auth0Config: {
    clientID: 'oMU05wztkwP2Ui679DiRrb9WvIR5hK2B',
    domain: 'idana.eu.auth0.com',
    redirectUriBase: 'https://account.idana.com',
    responseType: 'token id_token',
    audience: 'https://backend.tomes.gmbh',
    scope: 'openid profile email',
    refreshTokenScope: 'openid email profile',
  },
  plausible: {
    domain: 'account.idana.com',
    trackLocalhost: false,
  },
};

export default prodConfig;
