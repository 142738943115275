import { IdanaSubscriptionPlan, IdanaOnboardingPlan } from '@/shared/interfaces';
import { IOrderState } from './order.interfaces';

export const orderStateDefault: IOrderState = {
  institutionName: '',
  email: '',
  isFreeAccount: false,
  address: {
    streetAndHouseNumber: '',
    zipCode: '',
    city: '',
  },
  idanaSubscription: {
    numberOfLicences: 1,
    isPlanWithDiscountSelected: false,
    selectedSubscriptionPlan: null,
    stripeCouponId: '',
    stripeCouponInfo: null,
    subscriptionPrices: {
      [IdanaSubscriptionPlan.IDANA_FLEXIBLE]: 129,
      [IdanaSubscriptionPlan.IDANA_PLUS]: 99,
      [IdanaSubscriptionPlan.IDANA_STARTER]: 49,
      [IdanaSubscriptionPlan.IDANA_STARTER_FLEXIBLE]: 59,
    },
  },
  idanaEditor: {
    isEditorSelected: false,
    price: 379,
  },
  onboarding: {
    prices: {
      [IdanaOnboardingPlan.IDANA_ONBOARDING_BASIS]: 399,
      [IdanaOnboardingPlan.IDANA_ONBOARDING_BASIS_PLUS]: 499,
    },
    selectedPlan: IdanaOnboardingPlan.IDANA_ONBOARDING_BASIS_PLUS,
  },
  paymentDetails: {
    paymentMethod: null,
    stripePaymentMethodId: null,
  },
};
