export enum IdanaSubscriptionPlan {
  IDANA_FLEXIBLE = 'IDANA_FLEXIBLE',
  IDANA_PLUS = 'IDANA_PLUS',
  IDANA_STARTER = 'IDANA_STARTER',
  IDANA_STARTER_FLEXIBLE = 'IDANA_STARTER_FLEXIBLE',
}

export enum IdanaOnboardingPlan {
  IDANA_ONBOARDING_BASIS = 'IDANA_ONBOARDING_BASIS',
  IDANA_ONBOARDING_BASIS_PLUS = 'IDANA_ONBOARDING_BASIS_PLUS',
}
