

















import { IdanaSubscriptionPlan } from '@/shared/interfaces';
import { Component, Prop, Emit, Vue } from 'vue-property-decorator';

@Component
export default class SubscriptionSelector extends Vue {
  @Prop({ required: true }) readonly savePercentage!: number;
  @Prop({ required: true }) readonly isStarterSelected!: boolean;

  switchValue = true;

  @Emit('change')
  change(switchValue: boolean) {
    if (this.isStarterSelected) {
      return switchValue ? IdanaSubscriptionPlan.IDANA_STARTER : IdanaSubscriptionPlan.IDANA_STARTER_FLEXIBLE;
    } else {
      return switchValue ? IdanaSubscriptionPlan.IDANA_PLUS : IdanaSubscriptionPlan.IDANA_FLEXIBLE;
    }
  }
}
