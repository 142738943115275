import { IdanaSubscriptionPlan } from '@/shared/interfaces';
import { IOrderState } from '@/store/modules';

import { formatNumber } from '../utils/formatters.utils';
import {
  idanaSubscriptionPlanBasicBenefits,
  idanaSubscriptionPlanMonthlyBenefits,
  idanaSubscriptionPlanYearlyBenefits,
} from './idana-product-card.const';
import { IIdanaProductBenefitsItem } from './idana-product-card.interfaces';

export function getIdanaSubscriptionPlanName(plan: IdanaSubscriptionPlan | null): string {
  if (plan === IdanaSubscriptionPlan.IDANA_FLEXIBLE) return 'Flexible';
  if (plan === IdanaSubscriptionPlan.IDANA_PLUS) return 'Plus';
  if (plan === IdanaSubscriptionPlan.IDANA_STARTER) return 'Starter';
  if (plan === IdanaSubscriptionPlan.IDANA_STARTER_FLEXIBLE) return 'Starter Flexible';

  return 'Fehler';
}

export function getIdanaSubscriptionPlanPeriodHint(plan: IdanaSubscriptionPlan | null): string {
  if (plan === IdanaSubscriptionPlan.IDANA_FLEXIBLE) return 'monatliche Abrechnung';
  if (plan === IdanaSubscriptionPlan.IDANA_PLUS) return 'jährliche Abrechnung';
  if (plan === IdanaSubscriptionPlan.IDANA_STARTER) return 'jährliche Abrechnung';
  if (plan === IdanaSubscriptionPlan.IDANA_STARTER_FLEXIBLE) return 'monatliche Abrechnung';

  return '';
}

export function getIdanaSubscriptionPlanPrice(orderState: IOrderState, plan: IdanaSubscriptionPlan | null): number {
  if (plan === null) return 0;

  return orderState.idanaSubscription.subscriptionPrices[plan] || 0;
}

export const getIdanaSubscriptionPlanSpecialBenefit = (
  isMonthlyPlan: boolean,
  discountValue: number,
  discountDuration = 0,
): IIdanaProductBenefitsItem => {
  let title = '';

  if (isMonthlyPlan) {
    if (!discountDuration) {
      title = `Spezielles Angebot für Sie: ${formatNumber(discountValue)}% Rabatt`;
    } else if (discountDuration === 1) {
      title = `Spezielles Angebot für Sie: ${formatNumber(discountValue)}% Rabatt für ${discountDuration} Monat`;
    } else if (discountDuration > 1) {
      title = `Spezielles Angebot für Sie: ${formatNumber(discountValue)}% Rabatt für ${discountDuration} Monate`;
    }
  } else {
    title = `Spezielles Angebot für Sie: ${formatNumber(discountValue)}% Rabatt für 1 Jahr`;
  }

  return {
    icon: 'mdi-check',
    colorClass: 'primary--text',
    title,
  };
};

export function getIdanaSubscriptionPlanBenefits(
  plan: IdanaSubscriptionPlan | null,
  discountValue = 0,
  discountMonthsDuration = 0,
): IIdanaProductBenefitsItem[] {
  if (!plan) return [];
  let benefitsList: IIdanaProductBenefitsItem[] = [...idanaSubscriptionPlanBasicBenefits];

  if (plan === IdanaSubscriptionPlan.IDANA_FLEXIBLE) {
    benefitsList = benefitsList.concat(idanaSubscriptionPlanMonthlyBenefits);
  }

  if (plan === IdanaSubscriptionPlan.IDANA_PLUS) {
    benefitsList = benefitsList.concat(idanaSubscriptionPlanYearlyBenefits);
  }

  if (discountValue > 0) {
    const specialBenefit = getIdanaSubscriptionPlanSpecialBenefit(
      plan === IdanaSubscriptionPlan.IDANA_FLEXIBLE,
      discountValue,
      discountMonthsDuration,
    );

    benefitsList = benefitsList.concat(specialBenefit);
  }

  return benefitsList;
}
