import { IdanaSubscriptionPlan } from '@/shared/interfaces';
import { IOrderState } from '@/store/modules';

import { formatNumber } from '../utils/formatters.utils';
import {
  idanaSubscriptionPlanMonthlyBenefits,
  idanaSubscriptionPlanStarterMonthlyBenefits,
  idanaSubscriptionPlanStarterYearlyBenefits,
  idanaSubscriptionPlanYearlyBenefits,
} from './idana-subscription-info.const';

export function getIdanaSubscriptionPlanName(plan: IdanaSubscriptionPlan | null): string {
  if (plan === IdanaSubscriptionPlan.IDANA_FLEXIBLE) return 'Flexible';
  if (plan === IdanaSubscriptionPlan.IDANA_PLUS) return 'Plus';
  if (plan === IdanaSubscriptionPlan.IDANA_STARTER) return 'Starter';
  if (plan === IdanaSubscriptionPlan.IDANA_STARTER_FLEXIBLE) return 'Starter Flexible';

  return 'Fehler';
}

export function getIdanaSubscriptionPlanPeriodHint(plan: IdanaSubscriptionPlan | null): string {
  if (plan === IdanaSubscriptionPlan.IDANA_FLEXIBLE) return 'pro Monat';
  if (plan === IdanaSubscriptionPlan.IDANA_PLUS) return 'jährlich';
  if (plan === IdanaSubscriptionPlan.IDANA_STARTER) return 'jährlich';
  if (plan === IdanaSubscriptionPlan.IDANA_STARTER_FLEXIBLE) return 'pro Monat';

  return '';
}

export function getSubscriptionPlanTotalCost(orderState: IOrderState, idanaProduct: IdanaSubscriptionPlan): number {
  const productPrice = orderState.idanaSubscription.subscriptionPrices[idanaProduct];
  const licencesCount = orderState.idanaSubscription.numberOfLicences;
  const isMonthlyplan =
    idanaProduct === IdanaSubscriptionPlan.IDANA_FLEXIBLE ||
    idanaProduct === IdanaSubscriptionPlan.IDANA_STARTER_FLEXIBLE;

  const monthlyPrice = productPrice * licencesCount;
  const yearlyPrice = monthlyPrice * 12;

  return isMonthlyplan ? monthlyPrice : yearlyPrice;
}

export function getIdanaSubscriptionPlanPrice(orderState: IOrderState, plan: IdanaSubscriptionPlan | null): number {
  if (plan === null) return 0;

  return orderState.idanaSubscription.subscriptionPrices[plan] || 0;
}

export const getDiscountText = (plan: IdanaSubscriptionPlan, discountValue: number, discountDuration = 0): string => {
  const isMonthlyPlan = plan === IdanaSubscriptionPlan.IDANA_FLEXIBLE;
  if (isMonthlyPlan) {
    if (discountDuration === 1) {
      return `${formatNumber(discountValue)}% Rabatt für ${discountDuration} Monat`;
    } else if (discountDuration > 1) {
      return `${formatNumber(discountValue)}% Rabatt für ${discountDuration} Monate`;
    }
    return `${formatNumber(discountValue)}% Rabatt`;
  } else {
    return `${formatNumber(discountValue)}% Rabatt für 1 Jahr`;
  }
};

export function getIdanaSubscriptionPlanBenefits(licencesCount: number, plan: IdanaSubscriptionPlan | null): string[] {
  const idanaStarterSurveyAmountBenefit = [`${licencesCount * 50} Befragungen pro Monat inklusive`];

  if (plan === IdanaSubscriptionPlan.IDANA_FLEXIBLE) {
    return idanaSubscriptionPlanMonthlyBenefits;
  }

  if (plan === IdanaSubscriptionPlan.IDANA_PLUS) {
    return idanaSubscriptionPlanYearlyBenefits;
  }

  if (plan === IdanaSubscriptionPlan.IDANA_STARTER) {
    return idanaStarterSurveyAmountBenefit.concat(idanaSubscriptionPlanStarterYearlyBenefits);
  }

  if (plan === IdanaSubscriptionPlan.IDANA_STARTER_FLEXIBLE) {
    return idanaStarterSurveyAmountBenefit.concat(idanaSubscriptionPlanStarterMonthlyBenefits);
  }

  return [];
}
